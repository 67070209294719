@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.navbar {
  padding: 0px 30px;
}
.navbar .navbar-brand {
  width: 10%;
}
.navbar .navbar-brand img {
  height: 7vw;
  width: auto;
}
.navbar .navbar-collapse .navbar-nav .nav-item {
  margin-bottom: 0.5rem;
}
.navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #155d27;
  margin: 0px 9px;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 350;
}
.navbar .navbar-collapse .navbar-nav .btn {
  margin: 0px 15px;
  border-radius: 25px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.navbar .navbar-collapse .navbar-nav .btn:hover {
  transform: scale(1.03);
  box-shadow: 0px 3px 6px rgba(0, 128, 0, 0.4);
}

@media (max-width: 992px) {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
  .navbar-nav .nav-item .btn {
    margin: 0px 15px;
    border-radius: 25px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  .navbar-nav .nav-item .btn:hover {
    transform: scale(1.03);
    box-shadow: 0px 3px 6px rgba(0, 128, 0, 0.4);
  }
}
@media (max-width: 576px) {
  .navbar .navbar-brand {
    width: 20%;
    display: flex;
    justify-content: center;
  }
  .navbar .navbar-brand img {
    width: 100%;
    height: auto;
  }
  .nav-link {
    font-size: 16px;
    text-align: left;
  }
  .btn {
    margin: 0px 15px;
    border-radius: 25px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 100%;
  }
  .btn:hover {
    transform: scale(1.03);
    box-shadow: 0px 3px 6px rgba(0, 128, 0, 0.4);
  }
}/*# sourceMappingURL=Navbar.css.map */