@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.story-section {
  padding: 40px 30px;
}
.story-section .row {
  align-items: center;
}
.story-section .highlight-text {
  font-size: 1.1rem;
  color: #155d27;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}
.story-section .tagline {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  color: #155d27;
  margin-bottom: 20px;
}
.story-section .story-content p {
  text-align: left !important;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  color: black;
  line-height: 1.6 !important;
  margin-bottom: 20px !important;
}
.story-section .story-content .benefits-list {
  list-style: none;
  padding: 0;
  font-size: 1rem;
  color: #155d27;
  line-height: 1.8;
}
.story-section .story-content .benefits-list li {
  margin-bottom: 5px;
}
.story-section .story-content .learn-more-btn {
  background-color: #155d27;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 1rem;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s;
}
.story-section .story-content .learn-more-btn:hover {
  background-color: #e0a800;
}
.story-section .story-images {
  position: relative;
}
.story-section .story-images .image-group {
  position: relative;
}
.story-section .story-images .image-group .main-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}
.story-section .story-images .image-group .small-image {
  height: 150px;
  width: auto;
  position: absolute;
  bottom: -15px;
  right: 20px;
  border-radius: 10px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .story-section .story-images {
    margin-top: 30px;
  }
  .story-section .story-images .projects-done {
    left: 20px;
    right: auto;
  }
}/*# sourceMappingURL=StoryComponent.css.map */