.whats-app svg {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: rgb(3, 71, 29);
  width: 36px;
  height: 36px;
}/*# sourceMappingURL=Whatsapp.css.map */