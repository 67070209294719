@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.customer-reviews {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}
.customer-reviews h2 {
  font-family: "Raleway", sans-serif;
  color: #155d27;
  font-size: 2em;
  margin-bottom: 15px;
}
.customer-reviews .reviews-summary {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: rgba(5, 170, 87, 0.8);
}
.customer-reviews .reviews-summary span {
  margin-right: 10px;
}
.customer-reviews .reviews-summary span:first-child {
  font-weight: bold;
  font-size: 1.5em;
}
.customer-reviews .reviews-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.customer-reviews .review-card {
  background: white;
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  text-align: left;
  flex-basis: calc(33.333% - 30px);
}
@media (max-width: 768px) {
  .customer-reviews .review-card {
    flex-basis: calc(50% - 30px);
  }
}
@media (max-width: 480px) {
  .customer-reviews .review-card {
    flex-basis: calc(100% - 30px);
  }
}
.customer-reviews .review-photo {
  border-radius: 50%;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
}
.customer-reviews .review-content h3 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
  font-family: "Roboto", sans-serif;
}
.customer-reviews .review-content .review-rating {
  color: gold;
  margin: 5px 0;
  font-size: 1.1em;
}
.customer-reviews .review-content p {
  font-size: 0.95em;
  color: #666;
}/*# sourceMappingURL=CustomerReview.css.map */