@import '../Variable/variable';

footer {
  background: linear-gradient(180deg, rgba(29, 84, 135, 0.8) 25%, rgba(9, 125, 64, 0.6) 100%), 
  url(https://cdn.ymaws.com/ashs.site-ym.com/graphics/footy.jpg) no-repeat center center / cover;
  padding: 20px 0px;
  color: #fff;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  #footerContentArea {
    height: max-content;
    padding-bottom: 10px;

    .footer-col {
      // Mixin for common styles
      #addr, h5 {
            font-size: 1.3rem;
            font-weight: 200;
            color: $footer-text;
            font-family: $raleway;
          }
      
      #clNmbr, #mailAdd {
        position: relative;
        display: inline-block;
        width: max-content;
        // color: $footer-text;
        font-weight: 300;
        font-family: $roboto;
        // border: 1px solid red;
        margin-top: 10px;
        // padding: 10px 0;
      
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $footer-text;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out;
        }
      
        &:hover:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    // h5{
    //   font-size: 1.2rem;   
    //   font-weight: 300;     // Sets the font size for the <h5>
    //   font-family: $raleway;
      
    // }  
    .list-unstyled {
        text-align: center;
        font-size: 1.2rem;        // Ensures list font size matches the <h5>
        font-weight: 300;         // Sets list items' font weight to match <h5>
      
        a {
          color: $footer-text;    // Styles the links with the same text color
          text-decoration: none;  // Removes the underline from the links
          text-align: left;
        }
      }
      
        
      }
      p{
        font-size: 1rem;
        font-weight: 200;
        color: $text;
        font-family:$raleway;
        padding-bottom: 20px;
      }
      .form-inline{
        .input-group{ 
          justify-content: center;
          .form-control{ 
            display: block;
            width: 100%;
            margin-bottom: 30px;
            border-radius: 20px;
            border: 1px solid $footer-text;
          }
          button{
            border: 1px solid $footer-text;
            padding: 10px 20px;
            margin-bottom: 20px;
            border-radius: 25px;
            font-family: $raleway;
            color: $footer-text;
            &:hover {
              color: green;
              -webkit-text-fill-color: green;
              transition: 0.5s;
            }
            
          }
          
        }
      }
      .social-links{
        .icons {
          svg {                    
            opacity: 0.7;
            transform: translateY(20px);
            transition: opacity 0.6s ease-out, transform 0.6s ease-out;
            width: 50px;
            height: 25px;
            fill: $footer-text;
                      &:hover {
                        fill: $text;
                      }
                    }
                    
                  }
                }
              }
            }
          
          

      //   }
      // }
//     }
//   }
// }


// @media (max-width: 850px) {
//   footer {
//     // padding: 20px 0px;
    
//     #footerContentArea {
//     .footer-col {
//       margin-top: 20px;
//       margin-bottom: 35px;
//       width: 100%;
      
//       #addr {
//         padding: 15px 10px;
//         font-size: 1.3rem;
//       }
      
//       #clNmbr {
//         padding-top: 15px;
//       }
      
//       .icons {
//         display: inline-flex;
//         justify-content: space-evenly;
//         padding: 10px 45px;
//         // border: 1px solid red;
//           margin-top: 10px;

//           svg {
//             margin: 0px;
//             width: 32px;
//             height: 32px;
//           }
//         }
//       }
//     }
//   }
// }

// @media (max-width: 992px) {
  
//   footer {
//     // padding: 20px 0px;
    
//     #footerContentArea {
//       .footer-col {
//         border: 1px solid red;
//         // margin-top: 20px;
//         // margin-bottom: 35px;
//         // width: 100%;
        
//         // #addr {
//         //   padding: 15px 10px;
//         //   font-size: 1.3rem;
//         // }
        
//         // #clNmbr {
//         //   padding-top: 15px;
//         // }
        
//         // .icons {
//         //   display: inline-flex;
//         //   justify-content: space-evenly;
//         //   padding: 10px 45px;
//         //   // border: 1px solid red;
//         //     margin-top: 10px;
  
//         //     svg {
//         //       margin: 0px;
//         //       width: 32px;
//         //       height: 32px;
//         //     }
//         //   }
//         }
//       }
//     }
// }
