/* ContactHeader.css */
@import '../Variable/variable';

.contact-header {
  background: $background;
  height: 6vh;
  transform: translateY(-100%);  // Start position above the viewport
  animation: slideIn 0.5s ease-out forwards;  // Applies slide-in animation

  .header-row {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    .col {
      color: $text;
      font-family: $raleway;
      font-size: 1.15rem;
      font-weight: 300;
    }
  }
}

/* Slide-in animation */
@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

/* Universal Media Query */
@media (max-width: 575px) {
  .contact-header {
    display: none;
  }
}

@media (min-width: 576px) {
  .contact-header .header-row .col {
    font-size: 1.0rem;
  }
}

@media (min-width: 768px) {
  .contact-header .header-row .col {
    font-size: 1.07rem;
  }
}


// /* ContactHeader.css */
// @import '../Variable/variable';

// .contact-header {
//   background: $background;
//   height: 6vh;
//   opacity: 0;  // Initial opacity
//   animation: fadeIn 2s ease-in-out forwards;  // Applies fade-in animation

//   .header-row {
//     height: 100%;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
    
//     .col {
//       color: $text;
//       font-family: $raleway;
//       font-size: 1.15rem;
//       font-weight: 300;
//     }
//   }
// }

// /* Fade-in animation */
// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// /* Universal Media Query */
// @media (max-width: 575px) {
//   .contact-header {
//     display: none;
//   }
// }

// @media (min-width: 576px) {
//   .contact-header .header-row .col {
//     font-size: 1.0rem;
//   }
// }

// @media (min-width: 768px) {
//   .contact-header .header-row .col {
//     font-size: 1.07rem;
//   }
// }
