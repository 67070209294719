/* ContactHeader.css */
@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.contact-header {
  background: #155d27;
  height: 6vh;
  transform: translateY(-100%);
  animation: slideIn 0.5s ease-out forwards;
}
.contact-header .header-row {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contact-header .header-row .col {
  color: rgb(255, 255, 255);
  font-family: "Raleway", sans-serif;
  font-size: 1.15rem;
  font-weight: 300;
}

/* Slide-in animation */
@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
/* Universal Media Query */
@media (max-width: 575px) {
  .contact-header {
    display: none;
  }
}
@media (min-width: 576px) {
  .contact-header .header-row .col {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .contact-header .header-row .col {
    font-size: 1.07rem;
  }
}/*# sourceMappingURL=ContactHeader.css.map */