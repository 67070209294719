@import "../Variable/variable";

.customer-reviews {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
  
  h2 {
    font-family: $raleway;
    color: $background;
    font-size: 2em;
    margin-bottom: 15px;
  }
  
  .reviews-summary {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: rgba(5, 170, 87, 0.8);
    span {
      margin-right: 10px;
      &:first-child {
        font-weight: bold;
        font-size: 1.5em;
      }
    }
  }
  
  .reviews-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .review-card {
    background: white;
    border-radius: 10px;
    padding: 15px;
    margin: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    text-align: left;
    flex-basis: calc(33.333% - 30px); // 3 cards per row, with margin
    
    @media (max-width: 768px) {
      flex-basis: calc(50% - 30px); // 2 cards per row on tablets
    }
    
    @media (max-width: 480px) {
        flex-basis: calc(100% - 30px); // 1 card per row on mobile
      }
    }
  
    .review-photo {
      border-radius: 50%;
      margin-right: 15px;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
    
    .review-content {
      h3 {
        margin: 0;
        font-size: 1.2em;
        color: #333;
        font-family: $roboto;
      }
      
      .review-rating {
        color: gold;
        margin: 5px 0;
        font-size: 1.1em;
      }
      
      p {
        font-size: 0.95em;
        color: #666;
      }
    }
  }  