@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.work-process {
  background: linear-gradient(135deg, #91afa5, #76c7b2, #5ab4ab);
  padding: 40px 0;
  text-align: center;
}
.work-process .work-process-subtitle {
  font-weight: 400;
  color: #ffff3f;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  opacity: 0.9;
}
.work-process .work-process-title {
  font-size: 28px;
  font-family: "Raleway", sans-serif, sans-serif;
  margin-bottom: 40px;
  color: #155d27;
}
.work-process .work-process-steps {
  display: flex;
  justify-content: center;
  gap: 30px;
}
@media (max-width: 768px) {
  .work-process .work-process-steps {
    flex-wrap: wrap;
  }
}
.work-process .work-process-step {
  background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
  border-radius: 12px;
  padding: 30px 20px;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}
.work-process .work-process-step .step-icon {
  font-size: 40px;
  color: #f9e814;
  margin-bottom: 20px;
}
.work-process .work-process-step .step-title {
  font-size: 24px;
  padding: 10px 0;
}
.work-process .work-process-step .step-number {
  width: 50px;
  height: 50px;
  background-color: #f9e814;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: #20603b;
}/*# sourceMappingURL=WorkProcess.css.map */