.whats-app{
    svg{ 
    position: fixed;
    bottom: 20px;
    right: 20px;
    // background-color: #25d366;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    
      fill: rgb(3, 71, 29); // Default color to white
      width: 36px; // Size set to half an inch (approx. 36px)
      height: 36px; // Height set to match width    
    }
}
