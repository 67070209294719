@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.container-fluid .carousel-item {
  padding: 10px;
  position: relative;
}

@media (max-width: 576px) {
  .container-fluid .carousel-item {
    position: relative;
    padding: 10px;
  }
}/*# sourceMappingURL=Carousels.css.map */