@import '../../Components/Variable/variable';

.additional-services-section {
  padding: 40px 15px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .section-title {
    // border: 1px solid red;
    font-family: $raleway;
    font-size: 2.2rem;
    font-weight: 300;
    color: $background;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;

  }
  
  .someCards {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: stretch;


      .card-body {
        padding: 25px;
        text-align: center;

        .card-subtitle {
          font-family: 'Roboto', sans-serif;
          font-size: 1.25rem;
          font-weight: 500;
          color: #27ae60;
          margin-bottom: 15px;
        }

        .card-text {
          border-radius: 5px;
          background: linear-gradient(135deg, #e2f9e5, #d1f4d1);  // Gradient background from light green to a lighter green
          font-family: $raleway;  // Slightly translucent background for contrast
          font-size: 1rem;
          padding: 10px;
          font-weight: 400;
          color: #666;
          line-height: 1.5;
        }
      }
    }
  }


// Media Queries for responsiveness
@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .additional-service-card .card-body {
    padding: 20px;

    .card-subtitle {
      font-size: 1.1rem;
    }

    .card-text {
      font-size: 0.95rem;
    }
  }
}

@media (max-width: 576px) {
  .section-title {
    font-size: 1.75rem;
  }

  .additional-service-card .card-body {
    padding: 15px;

    .card-subtitle {
      font-size: 1rem;
    }

    .card-text {
      font-size: 0.9rem;
    }
  }
}

