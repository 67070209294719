@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.contact-row {
  padding: 0px 5vw;
  display: flex;
  flex-wrap: wrap;
}
.contact-row .col-lg-6 .contact-card {
  margin: 10px 20px 10px 0px;
  padding: 20px;
  border-radius: 8px;
}
.contact-row .col-lg-6 .contact-card .contact-card-body .contact-card-title {
  font-family: "Raleway", sans-serif;
  padding-bottom: 3vh;
  text-align: center;
  font-size: 35px;
  font-weight: 350;
  color: #155d27;
}
.contact-row .col-lg-6 .contact-card .contact-card-body .contact-card-subtitle {
  font-family: "Roboto", sans-serif;
  padding-bottom: 3vh;
  text-align: left;
  font-size: 24px;
  font-weight: 1000;
  color: #155d27;
}
.contact-row .col-lg-6 .contact-card .contact-card-body .contact-card-text {
  font-family: "Roboto", sans-serif;
  padding: 0;
  text-align: left;
  font-size: 22px;
  font-weight: 350;
  color: #155d27;
}
.contact-row .col-lg-6 .contact-card .social-media {
  display: flex;
  justify-content: space-around;
}
.contact-row .col-lg-6 .contact-card .social-media a {
  margin: 4px 4px;
}
.contact-row .col-lg-6 .contact-details {
  height: 100%;
  padding: 10px;
}
.contact-row .col-lg-6 .contact-details h1 {
  font-size: 38px;
  text-align: center;
  margin-top: 20px;
  font-weight: 360;
  color: #155d27;
  font-family: "Roboto", sans-serif;
}
.contact-row .col-lg-6 .contact-details h3 {
  font-weight: 450;
  margin-left: 20px;
  font-size: 26px;
  color: #155d27;
  font-family: "Roboto", sans-serif;
  text-align: left;
}
.contact-row .col-lg-6 .contact-details a {
  color: #155d27;
  text-decoration: none;
  margin-left: 20px;
  padding-right: 20px;
}
.contact-row .col-lg-6 .contact-details .contact-address {
  padding: 20px 0px;
  color: #155d27;
  margin-left: 20px;
  font-size: 24px;
  font-weight: 280;
  letter-spacing: -0.5px;
}
.contact-row .col-lg-6 .contact-details .contact-address span {
  font-weight: 350;
}

@media (max-width: 992px) {
  .contact-row {
    padding: 0;
    margin: 20px;
    flex-wrap: wrap;
  }
  .contact-row .col-12 {
    width: 100%;
  }
  .contact-row .col-12 .contact-card {
    margin: 10px 0px;
    padding: 20px;
  }
  .contact-row .col-12 .contact-card .contact-card-body .contact-card-title {
    font-size: 32px;
  }
  .contact-row .col-12 .contact-card .contact-card-body .contact-card-subtitle {
    font-size: 20px;
  }
  .contact-row .col-12 .contact-card .contact-card-body .contact-card-text {
    font-size: 16px;
  }
  .contact-row .col-12 .contact-details h1 {
    font-size: 30px;
  }
  .contact-row .col-12 .contact-details h3 {
    font-size: 20px;
  }
  .contact-row .col-12 .contact-details .contact-address {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .contact-row {
    flex-direction: column;
    padding: 0 10px;
  }
  .contact-card, .contact-details {
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-card-body .contact-card-title {
    font-size: 28px;
  }
  .contact-card-body .contact-card-subtitle {
    font-size: 18px;
  }
  .contact-card-body .contact-card-text {
    font-size: 16px;
  }
  .social-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }
  .contact-details h1 {
    font-size: 24px;
  }
  .contact-details h3 {
    font-size: 18px;
  }
  .contact-details p.contact-address {
    font-size: 20px;
    font-family: "Raleway", sans-serif;
  }
  .contact-details .cell-number {
    display: block;
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=ContactSection.css.map */