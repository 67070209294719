.body {
  padding: 0;
  margin: 0;
  background: #E8CBC0; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #636FA4, #E8CBC0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.body .form {
  padding: 40px 30px;
  border-radius: 10px;
  background: #42275a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #734b6d, #42275a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}
.body .form .input-group {
  margin-bottom: 20px;
}
.body .form .input-group .input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 18px;
}
.body .form #SubmitBtn {
  font-size: 18px;
  cursor: pointer;
}/*# sourceMappingURL=Events.css.map */