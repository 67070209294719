@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.additional-services-section {
  padding: 40px 15px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.additional-services-section .section-title {
  font-family: "Raleway", sans-serif;
  font-size: 2.2rem;
  font-weight: 300;
  color: #155d27;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}
.additional-services-section .someCards {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.additional-services-section .someCards .card-body {
  padding: 25px;
  text-align: center;
}
.additional-services-section .someCards .card-body .card-subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: #27ae60;
  margin-bottom: 15px;
}
.additional-services-section .someCards .card-body .card-text {
  border-radius: 5px;
  background: linear-gradient(135deg, #e2f9e5, #d1f4d1);
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  padding: 10px;
  font-weight: 400;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }
  .additional-service-card .card-body {
    padding: 20px;
  }
  .additional-service-card .card-body .card-subtitle {
    font-size: 1.1rem;
  }
  .additional-service-card .card-body .card-text {
    font-size: 0.95rem;
  }
}
@media (max-width: 576px) {
  .section-title {
    font-size: 1.75rem;
  }
  .additional-service-card .card-body {
    padding: 15px;
  }
  .additional-service-card .card-body .card-subtitle {
    font-size: 1rem;
  }
  .additional-service-card .card-body .card-text {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=AdditionalServiceSection.css.map */