@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.services-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.services-title {
  font-family: "Raleway", sans-serif;
  margin: 20px 15px 30px;
  font-size: 2.2rem;
  font-weight: 222;
  color: #155d27;
  text-align: center;
  width: 100%;
}

.ourServices {
  padding: 0;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.service-card {
  border: none;
  margin: 0.5rem 15px;
  border-radius: 10px;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card-img-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.card-img-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}
.service-card:hover .card-img-wrapper img {
  opacity: 0.8;
}

.card-title {
  font-family: "Raleway", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #155d27;
  position: absolute;
  bottom: 15px;
  left: 20px;
  right: 20px;
  text-align: center;
  background-color: rgba(255, 253, 255, 0.5);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 577px) and (max-width: 768px) {
  .services-title {
    margin: 20px 0 30px;
    padding: 0;
    font-size: 1.15rem !important;
    font-weight: 300 !important;
    width: 100%;
  }
  .service-card {
    height: 100%;
  }
  .card-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 576px) {
  .services-title {
    margin: 20px 0 30px;
    padding: 0;
    font-size: 1.15rem !important;
    font-weight: 300 !important;
    width: 100%;
  }
  .service-card {
    height: 250px;
    margin: 0px !important;
  }
  .card-title {
    font-size: 1.4rem;
  }
}/*# sourceMappingURL=ServiceSection.css.map */