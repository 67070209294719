@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
#root {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.aboutUs h1 {
  font-size: 3.5rem !important;
  text-align: center;
  font-weight: 250;
  font-family: "Roboto", sans-serif;
  padding: 60px 30px 0px;
  color: green;
}
.aboutUs p {
  font-size: 1.5rem;
  font-weight: 300;
  padding: 10px 30px 40px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.aboutUs .groupCard {
  margin-bottom: 70px;
}
.aboutUs .groupCard .card {
  height: auto;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
.aboutUs .groupCard .card .card-body {
  padding: 15px;
  text-align: center;
}
.aboutUs .groupCard .card .card-body .card-title {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  color: rgb(26, 110, 26);
  margin-bottom: 10px;
}
.aboutUs .groupCard .card .card-body .card-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: rgb(37, 151, 37);
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}
.aboutUs .groupCard .card .card-body .card-subtitle-2 {
  font-size: 1.2rem;
  font-weight: 300;
  color: #155d27;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
}
.aboutUs .groupCard .card .card-body .card-text {
  padding: 15px;
  text-align: justify;
  font-size: 1rem;
  background: linear-gradient(135deg, #e2f9e5, #d1f4d1);
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
  line-height: 1.6;
}

.chooseUs .col-md-8 {
  background-color: #ffff3f;
  padding: 20px 25px;
  border-radius: 0 30px 30px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add this line for shadow effect */
}
.chooseUs .col-md-8 .card-main-body .this-card-title {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  padding: 10px 0px;
  color: rgb(26, 110, 26);
}
.chooseUs .col-md-8 .card-main-body .this-card-text {
  font-size: 1.2rem;
  font-weight: 350;
  text-align: left;
  padding: 30px 30px 30px 0px;
  font-family: "Roboto", sans-serif;
  color: rgb(0, 0, 0);
}
.chooseUs .col-md-4 img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.chooseUs .col-md-4 img:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(21, 93, 39, 0.5);
}

@media (max-width: 789px) {
  /* Phones */
  .aboutUs .groupCard {
    margin-bottom: 20px;
  }
  .aboutUs .groupCard .card {
    height: auto;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  .aboutUs .groupCard .card .card-body {
    padding: 15px;
    text-align: center;
  }
  .aboutUs .groupCard .card .card-body .card-title {
    font-size: 2.5rem;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    color: rgb(26, 110, 26);
    margin-bottom: 10px;
  }
  .aboutUs .groupCard .card .card-body .card-subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    color: rgb(37, 151, 37);
    font-family: "Roboto", sans-serif;
    margin-bottom: 10px;
  }
  .aboutUs .groupCard .card .card-body .card-subtitle-2 {
    font-size: 1.2rem;
    font-weight: 300;
    color: #155d27;
    font-family: "Roboto", sans-serif;
    margin-bottom: 15px;
  }
  .aboutUs .groupCard .card .card-body .card-text {
    padding: 15px;
    text-align: justify;
    font-size: 1rem;
    background: linear-gradient(135deg, #e2f9e5, #d1f4d1);
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    position: relative;
    margin-top: 10px;
    line-height: 1.6;
  }
  .chooseUs {
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
  }
  .chooseUs .col-md-8 {
    margin-top: 20px;
    padding-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
  }
  .chooseUs .col-md-8 .card-main-body {
    border-radius: 20px;
    background-color: #ffff3f;
    padding: 20px 25px;
    margin-bottom: 10px;
  }
  .chooseUs .col-md-8 .card-main-body .this-card-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    padding: 10px 0px;
    color: rgb(26, 110, 26);
  }
  .chooseUs .col-md-8 .card-main-body .this-card-text {
    font-size: 1rem;
    font-weight: 395;
    text-align: left;
    font-family: "Raleway", sans-serif;
    color: rgb(0, 0, 0);
  }
  .chooseUs .col-md-4 {
    padding: 0 25px;
  }
  .chooseUs .col-md-4 img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .chooseUs .col-md-4 img:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(21, 93, 39, 0.5);
  }
}
/* Tablets (768px to 991px) */
@media (min-width: 790px) and (max-width: 992px) {
  .aboutUs .groupCard .card {
    height: auto;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  .aboutUs .groupCard .card .card-body {
    padding: 15px;
    text-align: center;
  }
  .aboutUs .groupCard .card .card-body .card-title {
    font-size: 2.5rem;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    color: rgb(26, 110, 26);
    margin-bottom: 10px;
  }
  .aboutUs .groupCard .card .card-body .card-subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    color: rgb(37, 151, 37);
    font-family: "Roboto", sans-serif;
    margin-bottom: 10px;
  }
  .aboutUs .groupCard .card .card-body .card-subtitle-2 {
    font-size: 1.2rem;
    font-weight: 300;
    color: #155d27;
    font-family: "Roboto", sans-serif;
    margin-bottom: 15px;
  }
  .aboutUs .groupCard .card .card-body .card-text {
    padding: 15px;
    text-align: justify;
    font-size: 1rem;
    background: linear-gradient(135deg, #e2f9e5, #d1f4d1);
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    position: relative;
    margin-top: 10px;
    line-height: 1.6;
  }
}/*# sourceMappingURL=Home.css.map */