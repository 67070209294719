@import '../Variable/variable';

.landscape-area {
  margin: 40px 0px; // Center horizontally
  .landscape-section {
    margin: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
   
    .landscape-image {
      // height: 90vh;
      display: flex;
      justify-content: center;
      align-items: center; // Centers the image vertically
      padding: 10px;
      position: relative; // Necessary for the aspect ratio trick
      // border: 1px solid red;
    
      img {
        width: 100%;
        height: auto;
        max-width: 76%; // Image will never be wider than 76% of its container
        border-radius: 15px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2); // Soft shadow for depth
        object-fit: cover;
      }
    
      // Aspect ratio trick for 3:2 ratio
      &::before {
        content: "";
        display: block;
        padding-bottom: calc(2 / 3 * 100%); // 3:2 aspect ratio (height/width)
        width: 100%;
      }
    
      @media (max-width: 575px) {
        img {
          width: 100%; // Full width on small screens
          height: auto; // Keep height auto to maintain aspect ratio
          border-radius: 10px; // Slightly smaller rounding on smaller screens
        }
      }
    }
    
    
// .landscape-image {
//   height: 90vh;
//   display: flex;
//   justify-content: center;
//   padding: 10px;

//   img {
//     width: 76%;
//     height: auto;
//     object-fit: cover;
//     aspect-ratio: 3 / 2; // Ensures the aspect ratio is maintained
//     border-radius: 15px; // A subtle rounded effect
//     box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2); // Soft shadow for depth
//   }

//   @media (max-width: 992px) and (min-width: 576px) {
//     img {
//       width: 100%; // Adjusts width to fit the screen on medium devices
//       border: 1px solid red; // Debugging border
//     }
//   }

//   @media (max-width: 575px) {
//     img {
//       width: 100%; // Adjusts width to fit the screen on small devices
//       height: auto; // Maintains aspect ratio
//       border-radius: 10px; // Slightly smaller rounding for smaller screens
//     }
//   }
// }

    
    .landscape-info {
      padding: 20px;
      background: linear-gradient(135deg, rgba(175, 209, 173, 0.6), rgba(240, 180, 95, 0.6), rgba(232, 232, 232, 0.5));
      border-radius: 10px;
      border: none;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); // Subtle shadow for the info section
      text-align: center; // Center-align content for a cleaner look
      
      @media(max-width:992px){
        margin-top: 9vh;
      }
      
      .highlights {
        font-size: 1.75rem; // Slightly increased for more prominence
        font-weight: 600;
        color: #2E4D2B;
        font-family: $raleway, sans-serif; // Added fallback font for safety
        margin-bottom: 15px; // Added margin for better spacing
        text-transform: uppercase; // Optional for a bold, impactful style
        letter-spacing: 1px;
         // Added subtle spacing to improve legibility
        @media(max-width:768px){
          padding: 0;
          font-size: 1.5rem; // Slightly increased for more prominence
          font-weight: 600;

      }
      }
      
      .mainline {
        font-size: 1.25rem; // Slightly reduced for a cleaner hierarchy
        padding: 10px 10%;
        font-weight: 300;
        font-family: $raleway, sans-serif;
        color: #5E6D5C; // Replaced variable with a specific color for better control
        line-height: 1.6;
         // Improved line spacing for better readability
        @media (max-width: 768px){
        font-size: 1.25rem;
        padding: 0;
        font-size: 1.25rem;
        font-weight: 420;
      }
      }
      
      
      
      .services {
        display: flex;
        flex-wrap: wrap;
        gap: 20px; // Increased gap for better spacing between items
        justify-content: space-between;
      
        @media (max-width: 768px) {
          justify-content: center; // Aligns content better on medium screens
        }
      
        .service-item {
          display: flex;
          align-items: center;
          width: 45%;
          margin-bottom: 20px;
          padding: 15px; // Slightly increased padding for a more comfortable layout
          border-radius: 12px; // Softer border-radius for a modern look
          background-color: rgb(249, 249, 249, 0.6); // Light background for better contrast
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          
          &:hover {
            transform: translateY(-8px); // Slightly more lift on hover
            box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15); // Enhanced hover shadow for more depth
          }
          
          @media (max-width: 768px) {
            width: 48%;
          }
          
          @media (max-width: 576px) {
            width: 100%;
            justify-content: center;
            text-align: center;
          }
          
          .icon {
            font-size: 2.2rem;
            background-color: #e8f5e9; // Light background to highlight the icon
            border-radius: 50%;
            padding: 15px; // Increased padding for more prominence
            margin-right: 15px;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      
            @media (max-width: 576px) {
              font-size: 2rem;
              margin-right: 0;
              margin-bottom: 10px; // Space between icon and text on small screens
            }
          }
          
          p {
            font-size: 1.15rem;
            font-family: $raleway;
            padding: 15px;
            font-weight: 500;
            color: $background; // Dark gray for better readability instead of black
            margin: 0; // Removed border for a cleaner look
            
            @media (max-width: 576px) {
              font-size: 1rem;
              // border: 1px solid red;
            }
          }
        }
      }
      
    }
  }
}