@import '../../Components/Variable/variable';

#root {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.aboutUs {
  
  h1 {
    font-size: 3.5rem !important;
    text-align: center;
    font-weight: 250;
    font-family: $roboto;
    padding: 60px 30px 0px;
    color: green;
  }
  p {
    font-size: 1.5rem;
    font-weight: 300;
    padding: 10px 30px 40px;
    text-align: center;
    font-family: $roboto;
  }
  
  .groupCard{
    margin-bottom: 70px;
    .card {
      height: auto;  // Adjusting height to auto for better responsiveness
      border: none;  // Remove border to clean up appearance
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  // Adding subtle shadow for a more modern look
      border-radius: 10px;  // Rounded corners for a softer appearance
      overflow: hidden;  // Ensure content doesn't overflow out of rounded corners
      
      .card-body {
        padding: 15px;  // Increased padding for better spacing
        text-align: center;
        
        .card-title {
          font-size: 2.5rem;  // Adjusted font size for better scaling
          font-weight: 700;  // Bolder font weight for emphasis
          font-family: $raleway;
          color: rgb(26, 110, 26);
          margin-bottom: 10px;  // Space below the title
        }
        
        .card-subtitle {
          font-size: 1.5rem;  // Slightly smaller subtitle for balance
          font-weight: 400;  // Regular font weight
          color: rgb(37, 151, 37);
          font-family: $roboto;
          margin-bottom: 10px;  // Space below the subtitle
        }
        
        .card-subtitle-2 {
          font-size: 1.2rem;  // Adjusted font size
          font-weight: 300;  // Lighter font weight
          color: $background;
          font-family: $roboto;
          margin-bottom: 15px;  // Space below the second subtitle
        }
        
        .card-text {
          padding: 15px;  // Increased padding for better readability
          text-align: justify;
          font-size: 1rem;  // Adjusted font size for better readability
          background: linear-gradient(135deg, #e2f9e5, #d1f4d1);  // Gradient background from light green to a lighter green
          font-weight: 400;
          font-family: $raleway;
          background-color: rgba(255, 255, 255, 0.9);  // Slightly translucent background for contrast
          border-radius: 5px;  // Rounded corners for the text area
          position: relative;  // Changed to relative for better positioning control
          margin-top: 10px;  // Space above the text
          line-height: 1.6;  // Improved line spacing
        }
      }
    }
  }
}

.chooseUs { 
  .col-md-8 {
    background-color: $footer-text;
    padding: 20px 25px;
    border-radius: 0 30px 30px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add this line for shadow effect */
    .card-main-body {
      .this-card-title {
        text-align: center;
        font-size: 3rem;
        font-weight: 500;
        font-family: $raleway;
        padding: 10px 0px;
        color: rgb(26, 110, 26);
      }
      .this-card-text {
        font-size: 1.2rem;
        font-weight: 350;
        text-align: left;
        padding: 30px 30px 30px 0px;
        font-family: $roboto;
        color: rgb(0, 0, 0);
      }
    }
  }
  
  .col-md-4 {
    img {
      border-radius: 5px;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 10px 20px rgba($background, 0.5);
      }
    }
  }
}

@media (max-width: 789px){ 
  /* Phones */
 
  .aboutUs{ 
    .groupCard {
      margin-bottom: 20px;
      
      .card {
        height: auto;  // Adjusting height to auto for better responsiveness
        border: none;  // Remove border to clean up appearance
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  // Adding subtle shadow for a more modern look
        border-radius: 10px;  // Rounded corners for a softer appearance
        overflow: hidden;  // Ensure content doesn't overflow out of rounded corners
        
        .card-body {
          padding: 15px;  // Increased padding for better spacing
          text-align: center;
          
          .card-title {
            font-size: 2.5rem;  // Adjusted font size for better scaling
            font-weight: 700;  // Bolder font weight for emphasis
            font-family: $raleway;
            color: rgb(26, 110, 26);
            margin-bottom: 10px;  // Space below the title
          }
          
          .card-subtitle {
            font-size: 1.5rem;  // Slightly smaller subtitle for balance
            font-weight: 400;  // Regular font weight
            color: rgb(37, 151, 37);
            font-family: $roboto;
            margin-bottom: 10px;  // Space below the subtitle
          }
          
          .card-subtitle-2 {
            font-size: 1.2rem;  // Adjusted font size
            font-weight: 300;  // Lighter font weight
            color: $background;
            font-family: $roboto;
            margin-bottom: 15px;  // Space below the second subtitle
          }
          
          .card-text {
            padding: 15px;  // Increased padding for better readability
            text-align: justify;
            font-size: 1rem;  // Adjusted font size for better readability
            background: linear-gradient(135deg, #e2f9e5, #d1f4d1);  // Gradient background from light green to a lighter green
            font-weight: 400;
            font-family: $raleway;
            background-color: rgba(255, 255, 255, 0.9);  // Slightly translucent background for contrast
            border-radius: 5px;  // Rounded corners for the text area
            position: relative;  // Changed to relative for better positioning control
            margin-top: 10px;  // Space above the text
            line-height: 1.6;  // Improved line spacing
          }
        }
      }
    }
    
  }
  .chooseUs {
    padding: 10px;
    display: flex;
    flex-direction: column-reverse; // Make image appear first

    .col-md-8 {
      margin-top: 20px;
      padding-bottom: 0;
      border-radius: 0;
      box-shadow: none;
      background: transparent;  // Gradient background from white to light grey
      
      .card-main-body {
        border-radius: 20px;
        background-color: $footer-text;
        padding: 20px 25px;
        margin-bottom: 10px;
        
        .this-card-title {
          text-align: center;
          font-size: 2rem;
          font-weight: 500;
          font-family: $raleway;
          padding: 10px 0px;
          color: rgb(26, 110, 26);
        }
        .this-card-text {
          font-size: 1rem;
          font-weight: 395;
          // border: 1px solid red;
          text-align: left;
          font-family: $raleway;
          color: rgb(0, 0, 0);
        }
      }
    }
    .col-md-4{
      padding: 0 25px;
      img {
        border-radius: 5px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        
        &:hover {
          transform: scale(1.05);
          box-shadow: 0 10px 20px rgba($background, 0.5);
        }
      }
    }
  }    
  
}


/* Tablets (768px to 991px) */
@media (min-width: 790px) and (max-width: 992px) {
.aboutUs{
  .groupCard{
    // border: 1px solid red;
    .card {
      height: auto;  // Adjusting height to auto for better responsiveness
      border: none;  // Remove border to clean up appearance
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  // Adding subtle shadow for a more modern look
      border-radius: 10px;  // Rounded corners for a softer appearance
      overflow: hidden;  // Ensure content doesn't overflow out of rounded corners
      
      .card-body {
        padding: 15px;  // Increased padding for better spacing
        text-align: center;
        
        .card-title {
          font-size: 2.5rem;  // Adjusted font size for better scaling
          font-weight: 700;  // Bolder font weight for emphasis
          font-family: $raleway;
          color: rgb(26, 110, 26);
          margin-bottom: 10px;  // Space below the title
        }
        
        .card-subtitle {
          font-size: 1.5rem;  // Slightly smaller subtitle for balance
          font-weight: 400;  // Regular font weight
          color: rgb(37, 151, 37);
          font-family: $roboto;
          margin-bottom: 10px;  // Space below the subtitle
        }
        
        .card-subtitle-2 {
          font-size: 1.2rem;  // Adjusted font size
          font-weight: 300;  // Lighter font weight
          color: $background;
          font-family: $roboto;
          margin-bottom: 15px;  // Space below the second subtitle
        }
        
        .card-text {
          padding: 15px;  // Increased padding for better readability
          text-align: justify;
          font-size: 1rem;  // Adjusted font size for better readability
          background: linear-gradient(135deg, #e2f9e5, #d1f4d1);  // Gradient background from light green to a lighter green
          font-weight: 400;
          font-family: $raleway;
          background-color: rgba(255, 255, 255, 0.9);  // Slightly translucent background for contrast
          border-radius: 5px;  // Rounded corners for the text area
          position: relative;  // Changed to relative for better positioning control
          margin-top: 10px;  // Space above the text
          line-height: 1.6;  // Improved line spacing
        }
      }
    }
    }
}
  }