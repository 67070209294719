.contactImgBg{
    margin-top:10px;
    width: 95vw;
    height: auto;
    text-align: center;
    @media (max-width: 576px) {
        display: none;
    }
}
.contactImgSm{
    @media (min-width: 577px)
    { display: none; }
    @media (max-width: 576px) {
        
        width: 95vw;
        height: auto;
        text-align: center;
    }
}