@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.faq-row {
  display: inline;
}
.faq-row .col-12, .faq-row .col-lg-6 {
  width: 100%;
  padding: 0 15px;
}
.faq-row .intro-text {
  margin: 50px auto;
  padding: 20px;
  text-align: center;
}
.faq-row .intro-text h1 {
  font-size: 1.8rem;
  font-family: "Raleway", sans-serif;
  margin-bottom: 30px;
  font-weight: 400;
  color: #155d27;
}
.faq-row .intro-text p {
  text-align: left;
  padding: 0;
  font-size: 20px;
  font-weight: 395;
  color: #155d27;
  font-family: "Roboto", sans-serif;
}
.faq-row .faq-detail {
  border: 1px solid green;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 4px 30px rgba(21, 93, 39, 0.6);
}
.faq-row .faq-detail h2 {
  text-align: center;
  font-size: 1.8rem;
  font-family: "Raleway", sans-serif;
  margin-bottom: 30px;
  font-weight: 400;
  color: #155d27;
}
.faq-row .faq-detail .faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}
.faq-row .faq-detail .faq-item:last-child {
  border-bottom: none;
}
.faq-row .faq-detail .faq-item .faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.15rem;
  font-weight: 600;
  color: #155d27;
}
.faq-row .faq-detail .faq-item .faq-question .faq-toggle {
  font-size: 1.5rem;
  color: #155d27;
  transition: transform 0.3s ease;
}
.faq-row .faq-detail .faq-item .faq-answer {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.4;
  color: #155d27;
  animation: slideDown 0.3s ease forwards;
}
.faq-row .faq-detail .faq-item .faq-answer p {
  margin: 0;
  font-size: 20px;
}
.faq-row .faq-detail .faq-item.open .faq-answer {
  display: block;
}
.faq-row .faq-detail .faq-item.open .faq-toggle {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}/*# sourceMappingURL=FaqCompo.css.map */