@import '../Variable/variable';

.story-section {
    padding: 40px 30px;
    .row {
      align-items: center;
    }
    
    .highlight-text {
      font-size: 1.1rem;
      color: $background;
      font-family: $raleway;
      font-weight: 600;
      margin-bottom: 10px;
    }
    
    .tagline{
      font-family: $roboto;
      font-size: 2rem;
      color: $background;
      margin-bottom: 20px;
    }
    .story-content {
      // padding-right: 30px;
      
      p {
        text-align: left !important;
        font-size: 1rem;
        font-family: $raleway;
        color: black;
        line-height: 1.6 !important;
        margin-bottom: 20px !important;
      }
      
      .benefits-list {
        list-style: none;
        padding: 0;
        font-size: 1rem;
        color: $background;
        line-height: 1.8;
        
        li {
          margin-bottom: 5px;
        }
      }
      
      .learn-more-btn {
        // border: 1px solid red;
        background-color:$background;
        border: none;
        padding: 10px 20px;
        border-radius: 30px;
        font-size: 1rem;
        color: white;
        font-family: $roboto;
        font-weight: 400;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #e0a800;
        }
      }
    }
  
    .story-images {
      position: relative;
      .image-group {
        position: relative;
        
        .main-image {
          width: 100%;
          border-radius: 10px;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
        }
        
        .small-image {
          height: 150px;
          width: auto;
          position: absolute;
          bottom: -15px;
          right: 20px;
          border-radius: 10px;
          border: 5px solid $text;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
        }
      }
      }
      
      
    //   .projects-done {
    //     position: absolute;
    //     bottom: 10px;
    //     left: 20px;
    //     background-color: #fff;
    //     border-radius: 10px;
    //     padding: 10px 15px;
    //     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    //     text-align: center;
        
    //     .projects-number {
    //       font-size: 2rem;
    //       font-weight: bold;
    //       font-family: $raleway;
    //       color: $baclground;
    //     }
        
    //     .projects-text{
    //       font-size: 1rem;
    //       margin: 0;
    //       font-family: $raleway;
    //       color: $baclground;
    //     }
    //   }
    // }
  }
  
  @media (max-width: 767px) {
    .story-section {
      .story-images {
        margin-top: 30px;
        // border: 1px solid red;
        
        .projects-done {
          left: 20px;
          right: auto;
        }
      }
    }
  }
  