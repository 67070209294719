@import '../../Components/Variable/variable';

// .services-section {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
    
//     .services-title {
//         
//         font-family: $raleway;
//         margin: 20px 0px 30px;
//         font-size: 200%;
//         font-weight: 200;
//         color: $background;
//         text-align: center;
//         width: 100%;
        
//     }
    
//     .ourServices {
//         padding: 15px;
//         margin-bottom: 20px;
//         box-sizing: border-box;
//         
        
//         .service-card {
//             max-height: 80vh;
//             margin: 0.5rem 15px;
//             border-radius: 10px;
//             overflow: hidden;
//             position: relative;
//             box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//             transition: transform 0.4s ease-in-out, box-shadow 0.4s;
            
//             &:hover {
//                 transform: scale(1.05);
//                 box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
//             }
            
//             .card-img-wrapper {
//                 width: 100%;
//                 height: auto;
//                 object-fit: cover;
//                 display: block;
//                 transition: opacity 0.3s ease-in-out;
//                 &:hover {
//                     opacity: 0.5;
//                 }
//             }
            
//             .card-title {
//                 font-size: 1.8rem;
//                 font-weight: 600;
//                 color: #fff;
//                 position: absolute;
//                 bottom: 20px;
//                 left: 20px;
//             }
//         }
//     }
// }
  

// @media (min-width: 992px) and (max-width: 1099px) {
//   .col-md-6 {
//     .card {
//       height: 43vh;
//     }
//   }
// }

// @media (min-width: 787px) and (max-width: 991px) {
    //   .row-2 h1 {
        //     font-size: 2rem;
//     text-align: justify;
//   }

//   h2 {
//     font-size: 4rem;
//   }

//   .col-md-6 .card {
    //     height: 43vh;
//   }
// }

.services-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5; // Light gray background
}

.services-title {
    // border: 1px solid red;
    font-family: $raleway;
    margin: 20px 15px 30px;
    // margin: 20px 0 30px;
    font-size: 2.2rem;
    font-weight: 222;
    color: $background; // Dark gray for text
    text-align: center;
    width: 100%;
}

.ourServices {
    padding: 0;
    margin-bottom: 20px;
    box-sizing: border-box;
    
}

.service-card {
    border: none;
    margin: 0.5rem 15px;
    border-radius: 10px;
    height: 100%; // Adjust height
    background-color: #fff; // White background
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); // Stronger shadow on hover
    }
}

.card-img-wrapper {
    width: 100%;
    height: 100%; // Cover 70% of the card height
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 0.3s ease-in-out;
    }
    .service-card:hover & img {
        opacity: 0.8; // Slight dim on hover
    }
}
  
  .card-title {
      font-family: $raleway;
    //
      font-size: 1.8rem;
      font-weight: 400;
      color:$background; // Dark gray text
      position: absolute;
      bottom: 15px;
      left: 20px;
      right: 20px;
      text-align: center;
      background-color: rgba(256,253,256, 0.5); // Semi-transparent background
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); // Shadow for title box
}

// Responsive Design
@media (min-width: 577px) and (max-width: 768px) {
    .services-title {
        margin: 20px 0 30px;
        padding: 0;
        font-size: 1.15rem !important;
        font-weight: 300 !important;
        width: 100%;
    }

    .service-card {
        height: 100%;
    }
    .card-title {
        font-size: 1.6rem;
    }
  }
  
  @media (max-width: 576px) {
    .services-title {
        margin: 20px 0 30px;
        padding: 0;
        font-size: 1.15rem !important;
        font-weight: 300 !important;
        width: 100%;
    }
    .service-card {
        height: 250px;
        margin: 0px !important;
    }
    .card-title {
        font-size: 1.4rem;
    }
  }
  


