.contactImgBg {
  margin-top: 10px;
  width: 95vw;
  height: auto;
  text-align: center;
}
@media (max-width: 576px) {
  .contactImgBg {
    display: none;
  }
}

@media (min-width: 577px) {
  .contactImgSm {
    display: none;
  }
}
@media (max-width: 576px) {
  .contactImgSm {
    width: 95vw;
    height: auto;
    text-align: center;
  }
}/*# sourceMappingURL=ProductsHero.css.map */