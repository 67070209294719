@import '../Variable/_variable.scss';


  .faq-row {
    // display: flex;
    // column-gap: 15px;
    display: inline;
    
    // Targeting each column within faq-row
    .col-12 , .col-lg-6{
      // border: 1px solid red;
      width: 100%; // Ensures full width on small screens
      padding: 0 15px;
      // Responsive behavior for larger screens
      // @media (min-width: 992px) {
      //   .col-lg-6 {
      //     flex: 0 0 50% !important; // Takes up half the width on large screens
      //     // max-width: 50%;
      //     padding: 0 15px; // Provides padding between columns
      //   }
      
    }

    // Intro Text Styling
    .intro-text {
      margin: 50px auto;
      padding: 20px;
      text-align: center;

      h1 {
        font-size: 1.8rem;
        font-family: $raleway;
        margin-bottom: 30px;
        font-weight: 400;
        color: $background;
      }

      p {
        text-align: left;
        padding: 0;
        font-size: 20px;
        font-weight: 395;
        color: $background;
        font-family: $roboto;
      }
    }


    // FAQ Container Styling
    .faq-detail{
      border: 1px solid green;
      margin: 50px auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 24px;
      box-shadow: 0 4px 30px rgba($background, 0.6);

      h2 {
        text-align: center;
        font-size: 1.8rem;
        font-family: $raleway;
        margin-bottom: 30px;
        font-weight: 400;
        color: $background;
      }

      .faq-item{
        border-bottom: 1px solid #ddd;
        padding: 15px 0;

        &:last-child {
          border-bottom: none;
        }

        .faq-question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          font-size: 1.15rem;
          font-weight: 600;
          color: $background;

          .faq-toggle {
            font-size: 1.5rem;
            color: $background;
            transition: transform 0.3s ease;
          }
        }

        .faq-answer {
          margin-top: 10px;
          font-size: 1rem;
          line-height: 1.4;
          color: $background;
          animation: slideDown 0.3s ease forwards;

          p {
            margin: 0;
            font-size: 20px;
          }
        }

        &.open .faq-answer {
          display: block;
        }

        &.open .faq-toggle {
          transform: rotate(180deg);
        }
      }
    }


  }


@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}