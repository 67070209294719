@import '../Variable/variable';

.contact-row {
  padding: 0px 5vw;
  display: flex;
  flex-wrap: wrap; // Ensure wrapping for overflowing content
  
  .col-lg-6 {
    .contact-card {
      margin: 10px 20px 10px 0px;
      padding: 20px;
      border-radius: 8px;

      .contact-card-body {
        .contact-card-title {
          font-family: $raleway;
          padding-bottom: 3vh;
          text-align: center;
          font-size: 35px;
          font-weight: 350;
          color: $background;
        }

        .contact-card-subtitle {
          font-family: 'Roboto', sans-serif;
          padding-bottom: 3vh;
          text-align: left;
          font-size: 24px;
          font-weight: 1000;
          color: $background;
        }

        .contact-card-text {
          font-family: $roboto;
          padding: 0;
          text-align: left;
          font-size: 22px;
          font-weight: 350;
          color: $background;
        }
      }

      .social-media {
        display: flex;
        justify-content: space-around;
        
        a {
          margin: 4px 4px;
        }
      }
    }

    .contact-details {
      height: 100%;
      padding: 10px;

      h1 {
        font-size: 38px;
        text-align: center;
        margin-top: 20px;
        font-weight: 360;
        color: $background;
        font-family: $roboto;
      }

      h3 {
        font-weight: 450;
        margin-left: 20px;
        font-size: 26px;
        color: $background;
        font-family: 'Roboto', sans-serif;
        text-align: left;
      }

      a {
        color: $background;
        text-decoration: none;
        margin-left: 20px;
        padding-right: 20px;
      }

      .contact-address {
        padding: 20px 0px;
        color: $background;
        margin-left: 20px;
        font-size: 24px;
        font-weight: 280;
        letter-spacing: -0.5px;
        
        span {
          font-weight: 350;
        }
      }
    }
  }
}

// Media queries for responsiveness
@media (max-width: 992px) {
  .contact-row {
    padding: 0;
    margin: 20px;
    flex-wrap: wrap; // Stack columns on smaller screens
    
    .col-12 {
      width: 100%; // Full width on mobile
      .contact-card {
        margin: 10px 0px;
        padding: 20px;
        .contact-card-body {
          .contact-card-title {
            font-size: 32px;
          }
          .contact-card-subtitle {
            font-size: 20px;
          }
          .contact-card-text {
            font-size: 16px;
          }
        }
      }

      .contact-details {
        h1 {
          font-size: 30px;
        }
        
        h3 {
          font-size: 20px;
        }

        .contact-address {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .contact-row {
    flex-direction: column; // Stack the entire layout vertically for very small screens
    padding: 0 10px; // Reduce padding on mobile
  }
  
  .contact-card, .contact-details {
    width: 100%; // Full width
    margin-bottom: 20px; // Add space between cards
  }
  
  .contact-card-body {
    .contact-card-title {
      font-size: 28px;
    }
    .contact-card-subtitle {
      font-size: 18px;
    }
    .contact-card-text {
      font-size: 16px;
    }
  }
  .social-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }
  .contact-details {
    h1 {
      font-size: 24px;
    }
    
    h3 {
      font-size: 18px;
    }
    
    p.contact-address {
      font-size: 20px;
      font-family: $raleway;
    }
    
    .cell-number{
      display: block;
      margin-bottom: 20px;
    }
  }
}
