@import '../Variable/variable';  // Ensure that this file contains all necessary variables

.work-process {
    background: linear-gradient(135deg, #91afa5, #76c7b2, #5ab4ab);
    padding: 40px 0;
    text-align: center;

    .work-process-subtitle {
        // font-size: 2.5vw;  // Responsive font size based on viewport width
        font-weight: 400;  // A normal, lighter font weight for subtleness
        color: $footer-text;  // Ensure this variable is defined
        font-family: $roboto;  // Font family with a fallback
        letter-spacing: 0.05em;  // Slight letter spacing for readability
        text-transform: uppercase;  // Transform to uppercase for emphasis
        opacity: 0.9;  // Slight opacity for a subtle effect
    }
    

    .work-process-title {
        font-size: 28px;
        font-family: $raleway, sans-serif;  // Ensure $raleway is defined, with fallback
        // padding: 20px 10px;
        margin-bottom: 40px;
        color: $background;  // Ensure $background is defined
    }

    .work-process-steps {
        display: flex;
        justify-content: center;
        gap: 30px;

        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
    }

    .work-process-step {
        background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);  // Deep space colors
        border-radius: 12px;
        padding: 30px 20px;
        width: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;  // Keeping text color white for contrast
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);

        .step-icon {
            // border: 1px solid red;
            font-size: 40px;
            color: #f9e814;
            margin-bottom: 20px;
        }

        .step-title {
            font-size: 24px;
            padding: 10px 0;
            // border: 1px solid red;
            // margin-bottom: 20px;
        }

        .step-number {
            width: 50px;
            height: 50px;
            background-color: #f9e814;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 18px;
            color: #20603b;
        }
    }
}
