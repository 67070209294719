@import '../Variable/variable';
.container-fluid{ 
.carousel-item {
  padding: 10px;
  position: relative;
  // margin: 0px 10px;
  // border: 1px solid red;
    // img {
      // height: 85vh;
      // object-fit: cover;
    // }
    // span, p {
    //   position: absolute;
    //   width: 100%;
    //   text-align: center;
    //   font-weight: 800;
    //   font-size: 70px;
    //   color: rgb(238, 241, 233);
    //   stroke: rgb(184, 252, 184);
    //   background-color: none;
    //   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    //   animation: moveUp 1s forwards;
    // }
  }
}
  // @keyframes moveUp {
  //   from {
  //     bottom: 0;
  //     opacity: 0;
  //   }
  //   to {
  //     bottom: 50%;
  //     opacity: 1;
  //     transform: translateY(50%);
  //   }
  // }

  @media (max-width: 576px) {
    .container-fluid{ 
      .carousel-item {
        position: relative;
        padding: 10px;
        // source{
        //   border-radius: 20%;
        // }
        // height: 25%;
        // margin: 0px 10px;
        // border: 1px solid red;
          // img {
            // height: 85vh;
            // object-fit: cover;
          // }
          // span, p {
          //   position: absolute;
          //   width: 100%;
          //   text-align: center;
          //   font-weight: 800;
          //   font-size: 70px;
          //   color: rgb(238, 241, 233);
          //   stroke: rgb(184, 252, 184);
          //   background-color: none;
          //   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          //   animation: moveUp 1s forwards;
          // }
        }
      }    
  }   
           
  
  