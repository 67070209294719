@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.custom-product-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}
.custom-product-card:hover {
  transform: scale(1.05);
}

.custom-product-image {
  width: 100%;
  height: auto;
  max-width: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}

.custom-product-body {
  padding: 16px;
  background-color: #f8f9fa;
  text-align: center;
}

.custom-product-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.custom-product-price {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 12px;
}

.custom-product-description {
  font-size: 14px;
  margin-bottom: 16px;
  color: #495057;
}/*# sourceMappingURL=productCards.css.map */