@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
footer {
  background: linear-gradient(180deg, rgba(29, 84, 135, 0.8) 25%, rgba(9, 125, 64, 0.6) 100%), url(https://cdn.ymaws.com/ashs.site-ym.com/graphics/footy.jpg) no-repeat center center/cover;
  padding: 20px 0px;
  color: #fff;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}
footer #footerContentArea {
  height: -moz-max-content;
  height: max-content;
  padding-bottom: 10px;
}
footer #footerContentArea .footer-col #addr, footer #footerContentArea .footer-col h5 {
  font-size: 1.3rem;
  font-weight: 200;
  color: #ffff3f;
  font-family: "Raleway", sans-serif;
}
footer #footerContentArea .footer-col #clNmbr, footer #footerContentArea .footer-col #mailAdd {
  position: relative;
  display: inline-block;
  width: -moz-max-content;
  width: max-content;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  margin-top: 10px;
}
footer #footerContentArea .footer-col #clNmbr:before, footer #footerContentArea .footer-col #mailAdd:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffff3f;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}
footer #footerContentArea .footer-col #clNmbr:hover:before, footer #footerContentArea .footer-col #mailAdd:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
footer #footerContentArea .footer-col .list-unstyled {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
}
footer #footerContentArea .footer-col .list-unstyled a {
  color: #ffff3f;
  text-decoration: none;
  text-align: left;
}
footer #footerContentArea p {
  font-size: 1rem;
  font-weight: 200;
  color: rgb(255, 255, 255);
  font-family: "Raleway", sans-serif;
  padding-bottom: 20px;
}
footer #footerContentArea .form-inline .input-group {
  justify-content: center;
}
footer #footerContentArea .form-inline .input-group .form-control {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 20px;
  border: 1px solid #ffff3f;
}
footer #footerContentArea .form-inline .input-group button {
  border: 1px solid #ffff3f;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 25px;
  font-family: "Raleway", sans-serif;
  color: #ffff3f;
}
footer #footerContentArea .form-inline .input-group button:hover {
  color: green;
  -webkit-text-fill-color: green;
  transition: 0.5s;
}
footer #footerContentArea .social-links .icons svg {
  opacity: 0.7;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  width: 50px;
  height: 25px;
  fill: #ffff3f;
}
footer #footerContentArea .social-links .icons svg:hover {
  fill: rgb(255, 255, 255);
}/*# sourceMappingURL=Footer.css.map */