@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
.landscape-area {
  margin: 40px 0px;
}
.landscape-area .landscape-section {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.landscape-area .landscape-section .landscape-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
}
.landscape-area .landscape-section .landscape-image img {
  width: 100%;
  height: auto;
  max-width: 76%;
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  -o-object-fit: cover;
     object-fit: cover;
}
.landscape-area .landscape-section .landscape-image::before {
  content: "";
  display: block;
  padding-bottom: 66.6666666667%;
  width: 100%;
}
@media (max-width: 575px) {
  .landscape-area .landscape-section .landscape-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}
.landscape-area .landscape-section .landscape-info {
  padding: 20px;
  background: linear-gradient(135deg, rgba(175, 209, 173, 0.6), rgba(240, 180, 95, 0.6), rgba(232, 232, 232, 0.5));
  border-radius: 10px;
  border: none;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}
@media (max-width: 992px) {
  .landscape-area .landscape-section .landscape-info {
    margin-top: 9vh;
  }
}
.landscape-area .landscape-section .landscape-info .highlights {
  font-size: 1.75rem;
  font-weight: 600;
  color: #2E4D2B;
  font-family: "Raleway", sans-serif, sans-serif;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .landscape-area .landscape-section .landscape-info .highlights {
    padding: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
}
.landscape-area .landscape-section .landscape-info .mainline {
  font-size: 1.25rem;
  padding: 10px 10%;
  font-weight: 300;
  font-family: "Raleway", sans-serif, sans-serif;
  color: #5E6D5C;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .landscape-area .landscape-section .landscape-info .mainline {
    font-size: 1.25rem;
    padding: 0;
    font-size: 1.25rem;
    font-weight: 420;
  }
}
.landscape-area .landscape-section .landscape-info .services {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .landscape-area .landscape-section .landscape-info .services {
    justify-content: center;
  }
}
.landscape-area .landscape-section .landscape-info .services .service-item {
  display: flex;
  align-items: center;
  width: 45%;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 12px;
  background-color: rgba(249, 249, 249, 0.6);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.landscape-area .landscape-section .landscape-info .services .service-item:hover {
  transform: translateY(-8px);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
}
@media (max-width: 768px) {
  .landscape-area .landscape-section .landscape-info .services .service-item {
    width: 48%;
  }
}
@media (max-width: 576px) {
  .landscape-area .landscape-section .landscape-info .services .service-item {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}
.landscape-area .landscape-section .landscape-info .services .service-item .icon {
  font-size: 2.2rem;
  background-color: #e8f5e9;
  border-radius: 50%;
  padding: 15px;
  margin-right: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
@media (max-width: 576px) {
  .landscape-area .landscape-section .landscape-info .services .service-item .icon {
    font-size: 2rem;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.landscape-area .landscape-section .landscape-info .services .service-item p {
  font-size: 1.15rem;
  font-family: "Raleway", sans-serif;
  padding: 15px;
  font-weight: 500;
  color: #155d27;
  margin: 0;
}
@media (max-width: 576px) {
  .landscape-area .landscape-section .landscape-info .services .service-item p {
    font-size: 1rem;
  }
}/*# sourceMappingURL=LandscapeComponent.css.map */