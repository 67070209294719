@import '../Variable/variable';

.navbar {
  padding: 0px 30px;
  
  .navbar-brand {
    width: 10%;
    img {
      // border: 1px solid red;
      height: 7vw;
      width: auto;
    }
  }
  
  // Styling for the collapsed navbar on small screens
  .navbar-collapse {
    .navbar-nav {
      .nav-item {
        margin-bottom: 0.5rem; 
        
        .nav-link {
          // border:1px solid red;
          color: $background;
          margin: 0px 9px;
          font-family: $raleway;
          font-size: 20px;
          font-weight: 350;
          
        }
      }
        
        .btn {
          margin: 0px 15px;
          border-radius: 25px;
          transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
          
          &:hover {
            transform: scale(1.03);
            box-shadow: 0px 3px 6px rgba(0, 128, 0, 0.4);
          }
        }
  }
}
}

@media (max-width: 992px) {
  .navbar-nav {
    .nav-item {
      margin-bottom: 0.5rem;
      
      .btn {
        margin: 0px 15px;
        border-radius: 25px;
        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
        
        &:hover {
            transform: scale(1.03);
            box-shadow: 0px 3px 6px rgba(0, 128, 0, 0.4);
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .navbar {
      // color: $footer-link;
      // border: 1px solid red;
      // padding: 10px;
      // margin: 0;

      .navbar-brand {
        width: 20%;
        display: flex;
        justify-content: center;
        
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .nav-link {
      font-size: 16px; // Smaller font size for small screens
      text-align: left;
    }

    .btn {
      margin: 0px 15px;
      border-radius: 25px;
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      width: 100%;
      &:hover {
        transform: scale(1.03);
        box-shadow: 0px 3px 6px rgba(0, 128, 0, 0.4);
      }
    }
  }

